<template>
  <default-layout>
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-12" v-if="dataFetched">
            <!--          <div class="col-md-12" v-if="bookingOrder && bookingOrder.payment_name">-->
            <client-only
              ><component
                :is="paymentName.replace('_', '-')"
                :paymentMethodName="paymentMethodName"
                :virtualAccountNumber="virtualAccountNumber"
                :viewMybookingUrl="viewMybookingUrl"
                :orderNumber="orderNumber"
                :actions="actions"
                :price="price"
              ></component
            ></client-only>
          </div>
        </div>
      </div>
    </section>
  </default-layout>
</template>

<script>
import { mapState } from 'vuex';
const PermataVa = () => import('@/components/booking/how-to-pay/permata');
const BniVa = () => import('@/components/booking/how-to-pay/bni');
const Gopay = () => import('@/components/booking/how-to-pay/gopay');
import DefaultLayout from '@/layouts/default';
import DefaultHeadMixin from '@/mixins/defaultHead';

export default {
  name: 'index',
  mixins: [DefaultHeadMixin],
  middleware: ['auth', 'nonAdminListingPartner'],
  async created() {
    // await store.dispatch('mybooking/getCaraPembayaran', params.uuid);
    console.log('PARAMSNYA NIHCCC 1: ', this.$route.params);
    console.log('PARAMSNYA NIHCCC 2: ', this.$route.query);
    console.log(this.$route.query);
    if (this.$route.query.type === 'SELL_TRANSACTION') {
      const { data } = await this.$http.get('/api/v2/sell_transaction/how_to_pay', {
        params: {
          uuid: this.$route.params.uuid,
        },
      });
      console.log('responsee', data);
      this.paymentMethodName = data.data.payment_method_title;
      this.virtualAccountNumber = data.data.virtual_account;
      this.viewMybookingUrl = `/mybooking/detail?type=SELL_TRANSACTION&uuid=${data.data.uuid}`;
      this.paymentName = data.data.payment_name;
      this.actions = data.data.actions;
      this.price = data.data.price;
      this.orderNumber = data.data.order_number;
      console.log('OAKSODKASD,', this.orderNumber);
      this.dataFetched = true;
    } else if (this.$route.query.type === 'RENT_TRANSACTION') {
      const { data } = await this.$http.get('/api/v2/rent_transaction/how_to_pay', {
        params: {
          uuid: this.$route.params.uuid,
        },
      });
      console.log('responsee', data);
      this.paymentMethodName = data.data.payment_method_title;
      this.actions = data.data.actions;
      this.virtualAccountNumber = data.data.virtual_account;
      this.viewMybookingUrl = `/mybooking/detail?type=RENT_TRANSACTION&uuid=${data.data.uuid}`;
      this.paymentName = data.data.payment_name;
      this.price = data.data.price;
      this.orderNumber = data.data.order_number;
      console.log('OAKSODKASD,', this.orderNumber);
      this.dataFetched = true;
    } else {
      await this.$store.dispatch('v2/booking/getBookingOrderFromUuid', this.$route.params.uuid);
      this.paymentMethodName = this.bookingOrder.jenis_transfer;
      this.virtualAccountNumber = this.bookingOrder.virtual_account;
      this.viewMybookingUrl = `/mybooking/detail?type=BOOKING&uuid=${this.bookingOrder.uuid}`;
      this.paymentName = this.bookingOrder.payment_name;
      this.price = this.bookingOrder.final_price;
      this.orderNumber = this.bookingOrder.order_number;

      this.dataFetched = true;
    }
    // await store.dispatch('v2/booking/getBookingOrderFromUuid', params.uuid);
  },
  data() {
    return {
      paymentMethodName: null,
      virtualAccountNumber: null,
      actions: null,
      viewMybookingUrl: null,
      orderNumber: null,
      dataFetched: false,
      price: null,
      breakLoop: false,
    };
  },
  components: {
    PermataVa,
    BniVa,
    Gopay,
    DefaultLayout,
  },
  computed: {
    ...mapState({
      bookingOrder: state => state.v2.booking.bookingOrder,
    }),
  },
  async mounted() {
    // console.log(this.bookingOrder.payment_name.replace('_','-'));
    // await this.$store.dispatch('v2/booking/getBookingOrderFromUuid', this.$route.params.uuid);
    // this.$store.dispatch('mybooking/getCaraPembayaran', this.$route.params.uuid);

    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (this.breakLoop) {
        break;
      }
      const result = await this.checkValid();
      if (!result) {
        this.breakLoop = true;
        this.$router.replace(this.viewMybookingUrl);
      }
    }
  },
  methods: {
    async checkValid() {
      try {
        await new Promise(resolve => setTimeout(resolve, 4000));
        const { data } = await this.$http.get('/api/v2/how-to-pay/check-active', {
          params: {
            uuid: this.$route.params.uuid,
            type: this.$route.query.type,
          },
        });

        return data.data;
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
  beforeDestroy() {
    this.breakLoop = true;
  },
};
</script>
